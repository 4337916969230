import React, { useEffect, useState } from 'react'
import { Typography, Box, Switch, TextField } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Row, useStyles } from './style'
import { InputLabel } from './InputLabel'
import Keys from '../../utils/Keys'
import { POP_TYPES } from './utils'

export const PopNotification = ({
  register,
  resetField,
  errors,
  title,
  pushTitle,
  pushMessage,
  titleField,
  messageField,
  popType,
}) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(true)

  useEffect(() => {
    if (popType === POP_TYPES.CHAT_GPT) {
      setChecked(true)
    }
  }, [popType])

  return (
    <>
      <Box className={classes.sectionWrapper}>
        <Typography variant="h5" className={classes.text}>
          {title}
        </Typography>
        <Switch
          checked={checked}
          onChange={() =>
            popType === POP_TYPES.CHAT_GPT
              ? undefined
              : setChecked(prev => !prev)
          }
        />
      </Box>

      {checked && (
        <>
          <Row>
            <Box className={classes.inputWrapper}>
              <TextField
                {...register(titleField, {
                  required: 'This field is required',
                })}
                className={classes.textField_pop}
                placeholder={
                  popType === POP_TYPES.RE_POP
                    ? 'Re-Pop push title'
                    : 'Pop push title'
                }
                name={titleField}
                inputProps={{
                  className: classes.input,
                  maxLength: Keys.PUSH_TITLE_SIZE,
                }}
              />
              <DeleteIcon
                onClick={() => {
                  resetField(titleField)
                }}
                className={classes.iconButton_pop}
              />
            </Box>
            <InputLabel
              currentError={errors[titleField]}
              errors={errors}
              name={titleField}
              field={pushTitle}
              inputSize={Keys.PUSH_TITLE_SIZE}
              label={
                popType === POP_TYPES.RE_POP
                  ? 'Type Re-Pop push text  * '
                  : 'Type Pop push text  * '
              }
            />
          </Row>
          <Row>
            <Box className={classes.inputWrapper}>
              <TextField
                {...register(messageField, {
                  required: 'This field is required',
                })}
                className={classes.textField_pop}
                placeholder={
                  popType === POP_TYPES.RE_POP
                    ? 'Re-Pop push Message'
                    : 'Pop push Message'
                }
                name={messageField}
                inputProps={{
                  className: classes.input,
                  maxLength: Keys.PUSH_MESSAGE_SIZE,
                }}
              />
              <DeleteIcon
                onClick={() => {
                  resetField(messageField)
                }}
                className={classes.iconButton_pop}
              />
            </Box>
            <InputLabel
              currentError={errors[messageField]}
              errors={errors}
              field={pushMessage}
              name={messageField}
              inputSize={Keys.PUSH_MESSAGE_SIZE}
              label={
                popType === POP_TYPES.RE_POP
                  ? 'Type Re-Pop push Message  * '
                  : 'Type Pop push Message  * '
              }
            />
          </Row>
        </>
      )}
    </>
  )
}
